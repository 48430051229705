import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import cpwhlogo from '../assets/diecoders-wht.png';
import cplogo from '../assets/diecoders.png';

export default function Navbar() {

    // Initialize dark mode based on localStorage, default to 'light' if not set
    const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark');

    // Toggle dark mode
    const toggleDarkMode = () => {
      setDarkMode(prevMode => {
        const newMode = !prevMode;
        // Save the new mode to localStorage
        localStorage.setItem('theme', newMode ? 'dark' : 'light');
        return newMode;
      });
    };
  
    // Apply dark mode class to the body element
    useEffect(() => {
      if (darkMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    }, [darkMode]);

  return (
    <nav className={`navbar navbar-expand-lg ${darkMode ? 'navbar-dark bg-dark' : 'navbar-light bg-light'}`}>
        <div className="container-fluid">
          {/* <Link className="navbar-brand" to="/">TextUtils</Link> */}
          {/* <Link className="navbar-brand" to="/">
            <img 
              src={cpwhlogo}      // If you placed the logo in the `src` folder and imported it
              alt="Company Logo"
              
              height="50"
              className="d-inline-block align-text-top"
            />
          </Link> */}
          <Link className="navbar-brand" to="/">
            {/* Display different logos based on darkMode */}
            <img
              src={darkMode ? cpwhlogo : cplogo}
              alt="Company Logo"
              height="35"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button className="btn btn-outline-secondary ms-auto order-2 order-sm-1" onClick={toggleDarkMode}>
            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
          </button>
          <button className="navbar-toggler order-2 order-sm-2 ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About</Link>
              </li>
            </ul>
          </div>
        </div>
    </nav>
  )
}
