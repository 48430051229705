import React from 'react';
import './styles.css'; // Import the dark mode CSS file

export default function About({ darkMode }) {
    return (
        <>
            <div className={`container my-5 ${darkMode ? 'container-dark' : ''}`}>
                <div className="text-center">
                    <h2 className={`mb-4 ${darkMode ? '#f8f9fa' : '#212529'}`}>
                        About Us
                    </h2>

                    {/* First Paragraph */}
                    <p className={`lead ${darkMode ? 'text-muted-dark' : 'text-muted-light'}`}>
                        Welcome to <strong>DieCoders Text Utility</strong>, your go-to solution for quick and efficient text manipulation.
                        Whether you need to format, analyze, or transform your text, our tool is designed to streamline your tasks effortlessly.
                        It caters to students, writers, and professionals who handle text regularly, providing a simple yet powerful platform to enhance your productivity.
                    </p>

                    {/* Second Paragraph */}
                    <p className={`lead ${darkMode ? 'text-muted-dark' : 'text-muted-light'}`}>
                        Our mission is to offer a user-friendly experience that simplifies common text editing tasks,
                        such as changing text case, removing extra spaces, and converting text to speech.
                        We continually update our features to meet your needs, ensuring a seamless and effective experience.
                        Try it out and see how it can make your text editing process faster and more enjoyable!
                    </p>

                    {/* Back to Home Button */}
                    <a href="/" className={`btn ${darkMode ? 'btn-outline-light' : 'btn-primary'} mt-3`}>
                        Back to Home
                    </a>
                </div>
            </div>
            <footer className="footer text-center my-4">
                <p>&copy; 2024 DieCoders. All rights reserved.</p>
            </footer>
        </>
    );
}
