import './App.css';
import About from './components/About';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";


function App() {
  //const [darkMode, setDarkMode] = useState(false); //Whether dark mode is enabled or not

  return (
    <>
      <Navbar/>
      <div className="container my-3">
        <Routes>
          <Route path="/" element={<TextForm />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
