import React, { useState } from 'react'
import './styles.css';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faFileAlt, faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import cpwhlogo from '../assets/diecoders-wht.png';
import cplogo from '../assets/diecoders.png';


export default function TextForm(props) {
    

    const handleUpClick = ()=>{
        let newText = text.toUpperCase();
        setText(newText)
    }

    const handleLoClick = ()=>{
        let newText = text.toLowerCase();
        setText(newText)
    }

    const handleClrClick = ()=>{
        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Nothing to clear!",  // Message displayed if the text field is empty
                showConfirmButton: false,
                timer: 1500
            });
            return;  // Exit the function if there's nothing to clear
        }

        let newText = '';
        setText(newText)
    }

    const handleCopyClick = () => {

        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",          // Position the alert at the top-right of the screen
                icon: "warning",             // Warning icon
                title: "Text field is empty!", // Title of the alert
                showConfirmButton: false,    // No confirm button, it will automatically disappear
                timer: 1500                   // Timer to auto-dismiss the alert after 1.5 seconds
            });
            return;  // Exit the function if the text is empty
        }

        // Copy text to clipboard
        navigator.clipboard.writeText(text).then(() => {
            Swal.fire({
                position: "top-end",          // Position the alert at the top-right of the screen
                icon: "success",              // Success icon
                title: "Text copied to clipboard!", // Title of the alert
                showConfirmButton: false,     // No confirm button, it will automatically disappear
                timer: 1500                   // Timer to auto-dismiss the alert after 1.5 seconds
            });
        }).catch((err) => {
            alert('Failed to copy text: ', err);
        });
    };

    const handleRemoveExtraSpaces = () => {
        let newText = text.replace(/\s+/g, ' ').trim();  // Replace multiple spaces with one and trim the text
        setText(newText);
    };

     // Function for Text-to-Speech
     const handleTextToSpeech = () => {
        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Please enter some text first!",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        // Create a new SpeechSynthesisUtterance object
        const speech = new SpeechSynthesisUtterance(text);
        speech.pitch = 1; // Set pitch level (0 to 2)
        speech.rate = 1;  // Set speech rate (0.1 to 10)
        
        // Speak the text
        window.speechSynthesis.speak(speech);

    };

    const downloadTextFile = () => {

        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",          // Position the alert at the top-right of the screen
                icon: "warning",             // Warning icon
                title: "Text field is empty!", // Title of the alert
                showConfirmButton: false,    // No confirm button, it will automatically disappear
                timer: 1500                   // Timer to auto-dismiss the alert after 1.5 seconds
            });
            return;  // Exit the function if the text is empty
        }

        const blob = new Blob([text], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'diecoders-txt-download.txt';
        link.click();
    };

    const downloadWordFile = () => {
        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Text field is empty!",
                showConfirmButton: false,
                timer: 1500
            });
            return;  // Exit the function if the text is empty
        }
    
        // Create a simple HTML template for the .docx file
        const docContent = `
            <html>
                <head>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        p { font-size: 14px; }
                    </style>
                </head>
                <body>
                    <p>${text.replace(/\n/g, '<br>')}</p>
                </body>
            </html>
        `;
    
        // Create a Blob from the text content
        const blob = new Blob([docContent], { type: 'application/msword' });
    
        // Use FileSaver.js to download the Word file
        saveAs(blob, 'diecoders-word-download.docx');
    };

    const downloadPdfFile = () => {
        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Text field is empty!",
                showConfirmButton: false,
                timer: 1500
            });
            return;  // Exit the function if the text is empty
        }
   
        // Create a new instance of jsPDF
        const doc = new jsPDF();
   
        // Set the font and font size
        doc.setFont("Arial", "normal");
        doc.setFontSize(12);
   
        // Split text into an array of lines that fit within the PDF width
        const pageWidth = doc.internal.pageSize.getWidth();
        const textLines = doc.splitTextToSize(text, pageWidth - 20); // 20px margin
   
        // Add text to the PDF, starting from 10px margin
        doc.text(textLines, 10, 10);
   
        // Save the generated PDF
        doc.save('diecoders-pdf-download.pdf');
   };
    
    const handleCapitalizeClick = () => {
        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Text field is empty!",
                showConfirmButton: false,
                timer: 1500
            });
            return;  // Exit the function if the text is empty
        }
    
        let newText = text
            .toLowerCase()  // Convert the entire text to lowercase first
            .split(' ')  // Split text by spaces to handle each word individually
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter of each word
            .join(' ');  // Join words back together
        
        setText(newText);
    };

    const handleInverseClick = () => {
        if (text.trim() === "") {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Text field is empty!",
                showConfirmButton: false,
                timer: 1500
            });
            return;  // Exit the function if the text is empty
        }
    
        let newText = text
            .split(' ')  // Split text by spaces to handle each word individually
            .map(word => word.split('').reverse().join(''))  // Reverse each word
            .join(' ');  // Join words back together
    
        setText(newText);
    };
    
    

    const handleOnChange = (event)=>{
        setText(event.target.value)
    }
    const [text, setText] = useState('');

    // Calculate word count by trimming and splitting on whitespace, checking for empty text
    const wordCount = text.trim() === ''
        ? 0
        : text.replace(/\s+/g, ' ')  // Normalize multiple spaces to a single space
              .split(' ')               // Split by single space
              .filter(word => word.length > 0)  // Filter out empty strings
              .length;
    
    // Calculate character count excluding spaces
    const charCountWithoutSpaces = text.replace(/\s+/g, '').length
    // Calculate sentence count by splitting text based on sentence-ending punctuation
    const sentenceCount = text.trim() === '' 
    ? 0 
    : text.split(/[.!?]+/).filter(sentence => sentence.trim().length > 0).length;
    
    // Calculate line count by splitting based on newline characters and filtering out empty lines
    const lineCount = text.trim() === '' 
    ? 0 
    : text.split('\n').filter(line => line.trim() !== '').length;



    const minutesRead = wordCount > 0 ? (0.008 * wordCount).toFixed(2) : 0;
    

  return (
    <>
        <div className="container">
            <h4>{props.heading}</h4>
            <div className="mb-3">
                <textarea className="form-control" value={text} placeholder="Enter text here" onChange={handleOnChange} id="myBox" rows="8"></textarea>
            </div>
            <div className="button-container">
            <button type="button" className="btn btn-primary btn-sm mx-1 my-2" onClick={handleUpClick}>UPPERCASE</button>
            <button type="button" className="btn btn-primary btn-sm mx-1 my-2" onClick={handleLoClick}>lowercase</button>
            <button type="button" className="btn btn-primary btn-sm mx-1 my-2" onClick={handleCapitalizeClick}>Capitalize</button>
            <button type="button" className="btn btn-primary btn-sm mx-1 my-2" onClick={handleInverseClick}>Inverse</button>
            <button type="button" className="btn btn-primary btn-sm m my-2 my-2" onClick={handleRemoveExtraSpaces}>Remove Extra Spaces</button>
            <div className="btn-group mx-1 my-2">
                <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    Download
                </button>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" onClick={downloadTextFile}><FontAwesomeIcon icon={faFileAlt} /> Download txt</a></li>
                    <li><a className="dropdown-item" onClick={downloadWordFile}><FontAwesomeIcon icon={faFileWord} /> Download docs</a></li>
                    <li><a className="dropdown-item" onClick={downloadPdfFile}><FontAwesomeIcon icon={faFilePdf} /> Download pdf</a></li>
                </ul>
            </div>
            <button type="button" className="btn btn-primary btn-sm mx-1 my-2" onClick={handleTextToSpeech}><FontAwesomeIcon icon={faVolumeUp} /></button>
            <button type="button" className="btn btn-primary btn-sm mx-1 my-2" onClick={handleCopyClick}>Copy</button>
            <button type="button" className="btn btn-primary btn-sm mx-1 my-2" onClick={handleClrClick}>Clear</button>
            </div>
        </div>
        <div className="container my-4">
            <div className="card shadow-sm border-0">
                <div className="card-header text-center bg-primary text-white py-2">
                    <h6 className="mb-0">📋 Your Text Summary</h6>
                </div>
                <div className="card-body py-2">
                    <div className="d-flex justify-content-around text-center">
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-file-earmark-word text-primary"></i>
                            <small>{wordCount}</small>
                            <span className="text-muted small">Words</span>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-123 text-success"></i>
                            <small>{charCountWithoutSpaces}</small>
                            <span className="text-muted small">Characters</span>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-chat-dots text-warning"></i>
                            <small>{sentenceCount}</small>
                            <span className="text-muted small">Sentences</span>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-list-ol text-info"></i>
                            <small>{lineCount}</small>
                            <span className="text-muted small">Lines</span>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-stopwatch text-danger"></i>
                            <small>{minutesRead}</small>
                            <span className="text-muted small">Minutes</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <footer className="footer text-center">
            <p>&copy; 2024 DieCoders. All rights reserved.</p>
        </footer> */}

        <footer className="footer text-center my-4">
            <p>&copy; 2024 DieCoders. All rights reserved.</p>
        </footer>

    </>
  )
}
